import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";

import Countdown, { CountdownRenderProps } from "react-countdown";

import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "1.jpg",
    thumbnail: "1.jpg",
  },
  {
    original: "2.jpg",
    thumbnail: "2.jpg",
  },
  {
    original: "3.jpg",
    thumbnail: "3.jpg",
  },
  // {
  //   original: "4.jpg",
  //   thumbnail: "4.jpg",
  // },
  {
    original: "5.jpg",
    thumbnail: "5.jpg",
  },
  {
    original: "6.jpg",
    thumbnail: "6.jpg",
  },
];

const rootElement = document.getElementById("root");

const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  }

  // Render a countdown
  return (
    <div className="container-sm">
      <div className="d-flex flex-row justify-content-center">
        <div className="">
          <p className="h3">{days}</p>
          <p className="lala">days</p>
        </div>
        <div className="p-md-5 p-2"></div>
        <div className="">
          <p className="h3">{hours}</p>
          <p className="lala">hours</p>
        </div>
        <div className="p-md-5 p-2"></div>
        <div className="">
          <p className="h3">{minutes}</p>
          <p className="lala">minutes</p>
        </div>
        <div className="p-md-5 p-2"></div>
        <div className="">
          <p className="h3">{seconds}</p>
          <p className="lala">seconds</p>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  return (
    <>
      <div className="container-sm text-center">
        <div className="d-flex justify-content-md-center align-items-center pt-5">
          <h1 className="pt-5">
            <div className="container-sm pb-5">
              <img src="aa.png" className="w-25 w-md-20 w-lg-20"></img>
            </div>
            Anna & Adi’s<br></br>Weekend Wedding Celebration
            <p className="lead pt-5 pb-5">
              We can’t wait to celebrate with you.<br></br>For travel and
              wedding details please see below.
            </p>
            <div className="mw-100">
              <img src="first.jpg" className="mw-100"></img>
            </div>
            <div className="text-center pb-5 pt-5">
              <Countdown
                date={new Date("2022-09-11T11:44:23+00:00")}
                renderer={renderer}
              />
              <div className="pt-3">
                <button className="btn btn-primary">
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfCKc2t4cr1sM5C7oE1uQEEcA-tNJD1A_F9E1vg-a5EgG0A2Q/viewform"
                  >
                    RSVP here
                  </a>
                </button>
              </div>
            </div>
          </h1>
        </div>
      </div>

      <div className="container-sm text-center pt-5 mg-5">
        <h1 className="pt-4 pb-5">Just the Two of Us</h1>
        <div className="">
          <div>
            <ImageGallery items={images} />
          </div>
        </div>
        <div className="w-lg-50 d-flex mx-auto pt-5">
          <p className="lead text-center">
            Anna and Adi met in London in the summer of 2020 (yes the height of
            COVID) and they have spent almost every day together ever since
            (many in COVID isolation).<br></br>
            <br></br>It was clear to both of them from the beginning that they
            wanted to get married, they got engaged in August 2021. Adi is
            originally from India and Anna is half Austrian-half American but
            they both call London home, which made for a fun choice of deciding
            where to get married. They decided to have an intimate legal signing
            with just their parents and siblings on 02.02.2022 in London but
            still want a proper (non-COVID-permitting) celebration.<br></br>
            <br></br>Anna and Adi both share a love for Austria and at the end
            of each summer they have made it a tradition to travel there
            together. They would therefore love to invite you to come to Vienna
            at the end of this summer to celebrate their love and marriage with
            a mix of all four cultures (Austrian, American, British {"&"}{" "}
            Indian).
          </p>
        </div>
        <h3 className="pt-5 pb-5">
          Not all roads lead to Rome, some lead to London Bridge
        </h3>
        <div className="mw-100">
          <img src="home.jpeg" className="mw-100"></img>
        </div>
        <p className="pt-2 pb-5 strong"></p>
        <p className="lead">
          We can’t wait to celebrate all of our cultural heritages with our
          family and friends from all around the world.<br></br>
          <br></br>Please see below for a description of the wedding weekend.
          <div className="container">
            <p className="lead pt-5">
              If you have any further questions about our wedding or logistics
              during the weekend,<br></br>please contact our wedding planners:
              <br></br>
              <br></br>
              <div className="lead">
                <a href="tel:+436645010858">Call Linda +43 664 5010858</a>
              </div>
              <br></br>
              <br></br>
              <div className="lead">
                <a href="tel:+436643959933">Call Verena +43 664 3959933</a>
              </div>
            </p>
          </div>
        </p>
      </div>

      <div className="container-sm text-center pt-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="">
                  <h6 className="card-title display-5 pb-3">
                    Friday September 9th
                  </h6>
                  <div id="content">
                    <ul className="timeline">
                      <li className="event" data-date="18:00 - 23:00">
                        <h3>Heuriger and Viennese Heurigen Cuisine Dinner</h3>
                        <p className="lead">
                          Vienna is the only metropolis that grows enough wine
                          within its city limits to make it worth mentioning!
                          <br></br>
                          <br></br>
                          Please join us for a fun evening at Mayer am
                          Pfarrplatz, a real Viennese “Heurigen” which actually
                          means: wine tavern offering its current vintage. Along
                          with fine wine or alternative beverages, you’ll
                          experience typical Viennese Heurigen cuisine. This
                          very casual atmosphere will give you a taste of
                          Vienna’s charm.<br></br>
                          <h3 className="pt-3">Dress code:</h3>
                          <p>
                            Comfy casual, the typical ‘Tracht’ (Lederhosen &
                            Dirndl) is absolutely encouraged!
                          </p>
                          If you are interested in investing in Tracht clothing
                          please see below websites:
                          <br></br>
                          <br></br>
                          <a target="_blank" href="https://willhaben.at/">
                            https://willhaben.at/
                          </a>
                          <br></br>
                          <a href="https://www.originalsalzburger.at/">
                            https://www.originalsalzburger.at/
                          </a>{" "}
                          & <br></br>
                          <a href="https://www.trachtenmaedl.at/rundgang/">
                            https://www.trachtenmaedl.at/rundgang/
                          </a>{" "}
                          <br></br>
                          <br></br>
                          Alternatively you can enquire at Tostmann via email (
                          <a href="mailto:office@tostmann.at?subject=Wedding%20Anna%20and%20Adi">
                            office@tostmann.at
                          </a>
                          ) where you can plan to rent an outfit in advance.
                          Upon your arrival in Vienna you can visit the store
                          for last minute alterations. A credit-card will be
                          required as a deposit until the clothes are returned.
                          <br></br>
                          <br></br>
                          Please state: "Hochzeitsfeier Anna & Adi“/ „Wedding
                          Anna & Adi“ as the subject of the email so that they
                          know.
                          <br></br>
                          <br></br>Please see their website:{" "}
                          <a target="_blank" href="https://tostmann.at/">
                            https://tostmann.at/
                          </a>
                          <br></br>
                          Address: Schottengasse 3a, 1010 Wien
                          <br></br>
                          <h3 className="pt-3">Transportation:</h3>
                          <p className="lead">
                            A Shuttle Bus from Hotel Beethoven will transport
                            you to the Heurigen starting at 17:30 and bring you
                            back at 23:00.
                          </p>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="">
                  <h6 className="card-title display-5 pb-3">
                    Saturday September 10th
                  </h6>
                  <div id="content">
                    <ul className="timeline">
                      <li className="event" data-date="16:00 - 04:00">
                        <h3>Vows ceremony and Reception</h3>
                        <p className="lead">
                          Anna and Adi plan to exchange vows at an intimate
                          ceremony at Anna's family home in Vienna. This will be
                          followed by a dinner and reception at the
                          Kunsthistorisches Museum (Art History Museum).
                          <br></br>
                          <br></br>
                          We hope you are excited to join us and be a part of
                          our Night at the Museum.
                          <br></br>
                          <br></br>
                          The museum will also be offering free guided tours
                          prior to the start of the reception, if this is
                          something you are interested in please indicate in the
                          RSVP.
                          <h3 className="pt-3">Dress code:</h3>
                          <p>Cocktail Attire</p>
                          <h3 className="pt-3">Transportation:</h3>
                          <p className="lead">
                            A Shuttle Bus from Hotel Beethoven will transport
                            you to and from Anna’s family home. The shuttle bus
                            will leave from the hotel to bring guests to the
                            ceremony at 14:30 and will begin bringing guests to
                            the Museum at 18:30.<br></br>
                            <br></br>
                            The museum is within walking distance to Hotel
                            Beethoven, find more information here about the
                            exact location.<br></br>
                            <br></br>{" "}
                            <a href="https://www.khm.at/en/">
                              https://www.khm.at/en/
                            </a>
                          </p>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="">
                  <h6 className="card-title display-5 pb-3">
                    Sunday September 11th
                  </h6>
                  <div id="content">
                    <ul className="timeline">
                      <li className="event" data-date="11:00 - 14:00">
                        <h3>Mehndi Brunch at Hotel Beethoven</h3>
                        <p className="lead">
                          Wedding festivities in India are nothing short of a
                          vivid burst of colour, joy, food and beautiful smiles.
                          <br></br>
                          <br></br>
                          One of the most significant events is the Mehndi
                          ceremony which is a 'Shringaar' ceremony, aimed at
                          ritualistically beautifying the bride.
                          <br></br>
                          <br></br>Mehndi, otherwise known as henna, is applied
                          on hands and feet and is associated with positive
                          spirits and good luck. Traditionally, it is said that
                          the darker the stain of the Mehndi, the more love the
                          bride will receive from her partner and in-laws!
                          <br></br>
                          <br></br>We would love for you to join us for the
                          Mehndi ceremony and celebrate in true Indian spirit
                          with lots of food, music, and dancing! Please join us
                          for an Indian style brunch on Sunday morning at the
                          Hotel Beethoven. A delicious Indian themed buffet will
                          give you a peak into Adi's homeland.
                          <br></br>
                          <br></br>
                          The brunch will be in the Hotel Beethoven and will
                          start around 11:00.
                          <br></br>
                          <h3 className="pt-3">Dress code:</h3>
                          <p className="lead">
                            Colorful, vibrant and if you have it or would like
                            to wear more traditional Indian attire like Lehenga,
                            Saree, Sharara etc.
                          </p>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-sm pb-5 pt-5 text-center">
        <img src="aa.png" className="w-25 w-md-20 w-lg-20"></img>
      </div>

      <div className="container-sm pb-5">
        <div className="text-center">
          <div className="pt-5">
            <button className="btn btn-primary">
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfCKc2t4cr1sM5C7oE1uQEEcA-tNJD1A_F9E1vg-a5EgG0A2Q/viewform"
              >
                RSVP here
              </a>
            </button>
          </div>
          <div className=""></div>
          <div className="pb-5 pt-5">
            <button className="btn btn-primary">
              <a href="/accommodation">See Accommodation Options</a>
            </button>
          </div>
          <div className="pb-5">
            <button className="btn btn-primary">
              <a href="/registry">See Registry</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
