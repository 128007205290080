import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";

import Countdown, { CountdownRenderProps } from "react-countdown";

import Home from "./pages/home";
import Accommodation from "./pages/accommodation";
import Registry from "./pages/registry";

import ImageGallery from "react-image-gallery";

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/accommodation" element={<Accommodation />} />
      <Route path="/registry" element={<Registry />} />
    </Routes>
  </Router>,

  document.getElementById("root")
);
