import React from "react";

const Accommodation = () => {
  return (
    <>
      <div className="container-sm text-center pt-5">
        <h1>Travel and accommodation</h1>
        <p className="lead pt-4 pb-3">
          We have the Hotel Beethoven blocked for the weekend, please click{" "}
          <a target="_blank" href="https://www.hotel-beethoven.at/en/rooms/">
            here for more details.
          </a>
        </p>
        <p className="lead">
          Room category Papageno / Klassik including breakfast
        </p>
        <div>
          <ul className="lead">€ 108,00 Einzelnutzung/single</ul>
          <ul className="lead">€ 126,00 Doppelnutzung /double</ul>
        </div>

        <p className="pt-4 lead">Room category Selektion including breakfast</p>
        <div>
          <ul className="lead">€ 135,00 Einzelnutzung/single</ul>
          <ul className="lead">€ 160,00 Doppelnutzung/double</ul>
        </div>

        <p className="pt-4 pb-4 lead">
          Room category Salon including breakfast
        </p>
        <div>
          <ul className="lead">€ 165,00 Einzelnutzung/single</ul>
          <ul className="lead">€ 190,00 Doppelnutzung/double</ul>
        </div>
      </div>
      <div className="container-sm text-center pt-3 pb-5">
        <button className="btn btn-primary">
          <a target="_blank" href="https://www.hotel-beethoven.at/en/rooms/">
            Visit Website
          </a>
        </button>
      </div>
      <div className="container-sm text-center pt-5">
        <p className="lead">
          Guests can reserve by emailing directly to info@hotelbeethoven.at with
          the subject Anna and Adis wedding.
        </p>
        <div className="pb-5">
          <button className="btn btn-primary">
            <a href="mailto:info@hotelbeethoven.at?subject=Anna%20and%20Adis%20wedding">
              Send E-Mail
            </a>
          </button>
        </div>
        <p className="lead pt-5 container-sm pb-5">
          Rooms are bookable from now. Free cancellations can be made until two
          days upon arrival!<br></br>You are welcome to stay anywhere in close
          proximity but be aware transportation to the wedding events will leave
          from the Hotel Beethoven.
        </p>
      </div>
    </>
  );
};

export default Accommodation;
