import React from "react";

const Registry = () => {
  return (
    <>
      <div className="container-sm text-center pt-5">
        <h1>Registry</h1>
        <div className="pb-5 pt-5">
          <div className="lead">
            Your presence on our special day is the greatest present of all!
            However, if you wish to celebrate with a gift, we have listed a few
            items you can contribute to on our gift registry alternatively a
            contribution to our honeymoon and home improvement wishing well
            would be warmly appreciated.
            <br></br>
            <br></br>
            <h1>Tropical Honeymoon</h1>
            <h2 className="pt-2">Contribute here:</h2>
            <br></br>(
            <a
              target="_blank"
              href="https://www.paypal.com/paypalme/darwedding"
            >
              https://www.paypal.com/paypalme/darwedding
            </a>
            )<br></br>
            <br></br>or <br></br>
            <br></br>
            Account name: Anna Julia Dar
            <br></br>
            Bank name: Santander
            <br></br>Sort code: 090129<br></br>Account number: 56258745
            <br></br>IBAN: GB63ABBY09012956258745<br></br>Swift code:
            ABBYGB2LXXX
            <div>
              <div className="mw-100 pt-5">
                <img src="1a.jpeg" className="w-50"></img>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <div className="mw-100 pt-5">
                <img src="1b.jpeg" className="w-50"></img>
              </div>
            </div>
            <br></br>
            <br></br>
            Please visit our gift registry
            <a
              href="https://www.weddingshop.com/buy/view/107168"
              target="_blank"
            >
              {" "}
              here
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registry;
